<template>
  <div>
    
    <!-- Div Loading -->
    <v-container fluid fill-height class="container-loading" v-if="currentView == 'loading'">
      <v-row
      align="center"
      justify="center"
      >
        <v-col cols="12" align="center" justify="center">
          <div class="dominoes">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-col>
        <v-col cols="12" align="center" justify="center">
          <span class="text-overline">Data Loading . . .</span>
        </v-col>
      </v-row>
    </v-container>

    <!-- Div Table All -->
    <div class="pa-0 pt-4 pr-6 content-right_div-dashboard" v-if="currentView == 'table all'">

      <v-row>
        <v-col
        v-for="(item, i) in listStation"
        :key="i"
        align="center" 
        justify="center"
        class="flex lg5-custom"
        >

              <v-card
              class="mx-1 my-2 pa-1 card-img-pulau"
              @click="openDetailsStation(item)"
              >
                  <v-img
                  :src="item.url"
                  max-width="210"
                  max-height="90"
                  class="mb-1"
                  ></v-img>
                  
                  <!-- max-width="150" -->
                  
                  <span>{{item.stationname}}</span> <br>
                  <small><b>({{item.stationid}})</b></small>
              </v-card>

        </v-col>
        <!-- <v-col>
          <template>
            <v-sheet
            class="mx-auto"
            elevation="0"
            >
              <v-slide-group
              class="pa-0"
              active-class="success"
              show-arrows
              >
                <v-slide-item
                v-for="(item, i) in listStation"
                :key="i"
                >
                  <v-card
                    class="ma-4 pa-2 text-left card-img-pulau"
                    height="auto"
                    width="150"
                    >
                      <v-img tile width="auto" height="65" class="mt-2" :src="item.url"></v-img>

                      <small class="pa-0 caption font-weight-regular" v-html="item.stationname"></small><br>
                    </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <v-simple-table
          fixed-header
          height="auto"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                  v-for="item in dataHeaderParams"
                  :key="item.icon"
                  class="text-center dont-break"
                  style="width:300px !important"
                  >
                    <v-icon :class="'mr-2 mb-1 '+item.color+'--text'">{{item.icon}}</v-icon>
                    <span><b>{{item.params}}</b></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="item in dataBodyParams"
                :key="item.stationID"
                >

                  <td class="text-left py-2">
                    <span class="dont-break">
                      <v-icon color="primary" class="mb-2 mr-2">mdi-island</v-icon>
                      <b>{{ item.STATION_NAME }}</b>
                    </span>
                    
                    <br>

                    <span>
                      {{ item.DATETIME_UI }}
                    </span>
                    
                  </td>

                  <td class="text-center">

                    <span
                    v-if="item.TEMPERATURE > item.phmax || item.TEMPERATURE < item.phmin"
                    class="alert-text"
                    >
                      {{ item.TEMPERATURE }}
                    </span>

                    <span v-else>{{ item.TEMPERATURE }}</span>

                  </td>

                  <td class="text-center">
                    
                    <span
                    v-if="item.SALINITY > item.salinitymax || item.SALINITY < item.salinitymin"
                    class="alert-text"
                    >
                      {{ item.SALINITY }}
                    </span>

                    <span v-else>{{ item.SALINITY }}</span>
                  
                  </td>

                  <td class="text-center">
                    
                    <span
                    v-if="item.TURBIDITY > item.turbiditymax || item.TURBIDITY < item.turbiditymin"
                    class="alert-text"
                    >
                      {{ item.TURBIDITY }}
                    </span>

                    <span v-else>{{ item.TURBIDITY }}</span>

                  </td>

                  <td class="text-center">
                    
                    <span
                    v-if="item.pH > item.phmax || item.pH < item.phmin"
                    class="alert-text"
                    >
                      {{ item.pH }}
                    </span>

                    <span v-else>{{ item.pH }}</span>
                  
                  </td>
                  
                  <td class="text-center">
                    
                    <span
                    v-if="item.DO_CON > item.doconmax || item.DO_CON < item.doconmin"
                    class="alert-text"
                    >
                      {{ item.DO_CON }}
                    </span>

                    <span v-else>{{ item.DO_CON }}</span>
         
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

    </div>

    <!-- Div Table Station Selected -->
    <div class="pa-0 pt-5 pr-5 content-right_div-dashboard" v-if="currentView == 'table single'">

      <!-- Card Summary Details about Pulau -->
      <v-row>
        
        <!-- Button Back to main dashboard -->
        <v-col cols="1" class="px-0" align="center" justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              small
              fab
              class="mr-0"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="backToMainDashboard"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Back to main dashboard</span>
          </v-tooltip>
          
        </v-col>

        <v-col class="pl-0">

          <!-- Card Atas -->
          <v-card class="pa-2 pr-4 mx-1 card-img-details">
            <v-row>

              <!-- Image -->
              <v-col cols="3">
                <v-img
                :src="dataImgSelected.url"
                max-width="280"
                class="mb-1"
                ></v-img>
              </v-col>

              <!-- Address -->
              <v-col cols="5" class="px-0 pt-4" align="left" justify="left">
                
                <v-row>
                  <v-col cols="1" class="pb-2">
                    <v-icon dense color="red">mdi-map-marker</v-icon>
                  </v-col>
                  <v-col class="pb-0">
                    <span class="text-caption">
                      {{dataImgSelected.stationaddress}}
                    </span>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="1" class="py-1">
                    <v-icon dense color="primary">mdi-phone</v-icon>
                  </v-col>
                  <v-col class="py-1">
                    <span class="text-caption">
                      {{dataImgSelected.phoneno}}
                    </span>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="1" class="py-1">
                    <v-icon dense color="success">mdi-fax</v-icon>
                  </v-col>
                  <v-col class="py-1">
                    <span class="text-caption">
                      {{dataImgSelected.faksno}}
                    </span>
                  </v-col>
                </v-row>

              </v-col>

              <!-- Divider -->
              <v-col cols="1" class="px-0 mx-0" align="center" justify="center">
                <v-divider
                vertical
                ></v-divider>
              </v-col>

              <!-- Title Pulau -->
              <v-col class="pl-0 pt-4">

                <v-row>
                  <v-col class="px-0 pb-2">
                    <span class="text-h5 primary--text">
                      <v-icon class="primary--text mb-2 mr-1">mdi-island</v-icon>
                      {{dataImgSelected.stationname}}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" class="pa-0">
                    <span class="text-caption">
                      <b>Station:</b>
                    </span>
                  </v-col>
                  <v-col class="py-0 pl-0">
                    <span class="text-caption">
                      {{dataImgSelected.jettyname}}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" class="pa-0">
                    <span class="text-caption">
                      <b>Latitude:</b>
                    </span>
                  </v-col>
                  <v-col class="py-0 pl-0">
                    <span class="text-caption">
                      {{dataImgSelected.latitude}}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" class="pa-0">
                    <span class="text-caption">
                      <b>Longitude:</b>
                    </span>
                  </v-col>
                  <v-col class="py-0 pl-0">
                    <span class="text-caption">
                      {{dataImgSelected.longitude}}
                    </span>
                  </v-col>
                </v-row>

              </v-col>

            </v-row>
          </v-card>

        </v-col>

      </v-row>

      <!-- Line -->
      <v-row>
        <v-col class="py-1">
          <v-divider></v-divider>
          <small>Current Reading: <b>{{displayUiDate(currentDatetime)}}</b></small>
        </v-col>
      </v-row>

      <!-- Content (Chart and others) -->
      <v-row>

        <v-col cols="12" class="pt-1">

          <!-- Content Details Params Single Pulau -->
          
          <!-- <v-row>
            <v-col class="py-0">
              <small>Current Reading: 2022-12-30 06:30:00</small>
            </v-col>
          </v-row> -->

          <!-- Header Min Max -->
          <v-row class="px-3">
            <v-col
            v-for="item in listParams"
            :key="item.tab"
            class="px-0 mb-1"
            align="center"
            justify="center"
            >
              <v-chip
              color="red"
              class="white--text mr-1"
              small
              >
                <small>Max: {{item.max.toFixed(2)}}</small>
              </v-chip>
              <v-chip
              color="secondary"
              class="white--text ml-1"
              small
              >
                <small>Min: {{item.min.toFixed(2)}}</small>
              </v-chip>
            </v-col>
          </v-row>

          <!-- Header Params -->
          <v-tabs
          v-model="tabContentSinglePulau"
          fixed-tabs
          >

            <v-tabs-slider></v-tabs-slider>

            <v-tab
            v-for="item in listParams"
            :key="item.tab"
            >
              <div >
                <v-row>
                  <v-col class="pa-0" align="center" justify="center">
                    <v-icon class="mb-1" dense>{{item.icon}}</v-icon>
                    {{item.tab}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0 black--text" align="center" justify="center">
                    <span v-html="item.value? item.value.toFixed(2) : '0:00'"></span> {{item.unit}}
                  </v-col>
                </v-row>
              </div>
            </v-tab>
          </v-tabs>

          <!-- Content -->
          <v-tabs-items v-model="tabContentSinglePulau">
            
            <!-- Chart Temperature -->
            <v-tab-item class="content-highchart-dashboard">
              <div class="pa-2">
                <highcharts
                :class="$vuetify.breakpoint.lgAndUp? 'highchartTemperature' : ''"
                :options="highchartTemperature"
                :constructorType="'stockChart'"
                ref="refHighchartTemperature"
                ></highcharts>
              </div>
            </v-tab-item>
            
            <!-- Chart Salinity -->
            <v-tab-item class="content-highchart-dashboard">
              <div class="pa-2">
                <highcharts
                :class="$vuetify.breakpoint.lgAndUp? 'highchartSalinity' : ''"
                :options="highchartSalinity" 
                :constructorType="'stockChart'"
                ref="refHighchartSalinity"
                ></highcharts>
              </div>
            </v-tab-item>
            
            <!-- Chart Turbidity -->
            <v-tab-item class="content-highchart-dashboard">
              <div class="pa-2">
                <highcharts
                :class="$vuetify.breakpoint.lgAndUp? 'highchartTurbidity' : ''"
                :options="highchartTurbidity" 
                :constructorType="'stockChart'"
                ref="refHighchartTurbidity"
                ></highcharts>
              </div>
            </v-tab-item>
            
            <!-- Chart pH -->
            <v-tab-item class="content-highchart-dashboard">
              <div class="pa-2">
                <highcharts
                :class="$vuetify.breakpoint.lgAndUp? 'highchartpH' : ''"
                :options="highchartpH" 
                :constructorType="'stockChart'"
                ref="refHighchartpH"
                ></highcharts>
              </div>
            </v-tab-item>
            
            <!-- Chart DO -->
            <v-tab-item class="content-highchart-dashboard">
              <div class="pa-2">
                <highcharts
                :class="$vuetify.breakpoint.lgAndUp? 'highchartDO' : ''"
                :options="highchartDO" 
                :constructorType="'stockChart'"
                ref="refHighchartDO"
                ></highcharts>
              </div>
            </v-tab-item>

          </v-tabs-items>

        </v-col>

      </v-row>

    </div>

    <v-snackbar
    v-model="snackbarError"
    >
      {{ textError }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import axios from "axios"
import { bus } from '@/main';

export default {

  data: () => ({

    snackbarError: false,
    textError: "",

    // Div Loading
    currentView: "loading",

    // Div Main Dashboard
    listStation: [],
    dataHeaderParams: [
      {
        params: "",
        icon: "",
        color: ""
      },
      {
        params: "Temperature (°C)",
        icon: "mdi-thermometer-lines",
        color: "orange"
      },
      {
        params: "Salinity (ppt)",
        icon: "mdi-water-opacity",
        color: "purple"
      },
      {
        params: "Turbidity (NTU)",
        icon: "mdi-waves",
        color: "blue"
      },
      {
        params: "pH",
        icon: "mdi-thermometer-probe",
        color: "primary"
      },
      {
        params: "DO (mg/L)",
        icon: "mdi-chart-bubble",
        color: "pink"
      },
    ],
    dataBodyParams: [],
    dataImgSelected: null,
    dataMinMax: [],
    dataDashboard: [],

    // Div Details Single Pulou
    tabContentSinglePulau: null,
    listParams: [
      { tab: "Temperature", icon: "mdi-thermometer-lines", unit: "°C", value: 0.00,  max: 0.00, min: 0.00, keyWords: "temperature", keyWordsTableDashboard: "TEMPERATURE" },
      { tab: "Salinity", icon: "mdi-water-opacity", unit: "ppt", value: 0.00, max: 0.00, min: 0.00, keyWords: "salinity", keyWordsTableDashboard: "SALINITY" },
      { tab: "Turbidity", icon: "mdi-waves", unit: "NTU", value: 0.00, max: 0.00, min: 0.00, keyWords: "turbidity", keyWordsTableDashboard: "TURBIDITY" },
      { tab: "pH", icon: "mdi-thermometer-probe", unit: "", value: 0.00, max: 0.00, min: 0.00, keyWords: "ph", keyWordsTableDashboard: "pH" },
      { tab: "DO", icon: "mdi-chart-bubble", unit: "mg/L", value: 0.00, max: 0.00, min: 0.00, keyWords: "do", keyWordsTableDashboard: "DO_CON" },
    ],
    // currentDatetime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19),
    currentDatetime: null,

    // ------------- Highchart --------------

    // Highchart Temperature
    // refHighchartTemperature: null,
    highchartTemperature: {
      chart: {
        // height: 'auto',
        // width: 'auto'
        type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        plotLines: [
          {
            value: 31,
            color: '#ffad10',
            dashStyle: 'ShortDot',
            width: 3,
          },
          {
            value: 31.99,
            color: '#ff0000',
            dashStyle: 'ShortDot',
            width: 3,
          },
          // {
          //   // value: 100,
          //   color: '#ff0000',
          //   dashStyle: 'ShortDash',
          // }
        ],
      },
      navigator: {
        enabled: false
      //     adaptToUpdatedData: false,
      },
      series: [
        {
          name: "Temperature",
          data: [],
          type: 'spline',
          zones: [
            {
              value: 31,
              color: '#00b050'
            },
            {
              value: 31.99,
              color: '#ffad10'
            },
            {
              // value: 100,
              color: '#ff0000'
            }
          ]
        }
      ],
      legend: {
          enabled: false
      },
    },

    // Highchart Salinity
    highchartSalinity: {
      chart: {
        // height: 500,
        // width: 'auto'
        // type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        // plotLines: [],
      },
      navigator: {
        enabled: false
      },
      series: [
        {
          name: "Salinity",
          data: [],
          tooltip: {
            valueDecimals: 2
          },
          color: "#015781",
        }
      ],
      legend: {
          enabled: false
      },
    },

    // Highchart Turbidity
    highchartTurbidity: {
      chart: {
        // height: 500,
        // width: 'auto'
        // type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        // plotLines: [],
      },
      navigator: {
        enabled: false
      },
      series: [
        {
          name: "Turbidity",
          data: [],
          tooltip: {
            valueDecimals: 2
          },
          color: "#015781",
        }
      ],
      legend: {
          enabled: false
      },
    },

    // Highchart pH
    highchartpH: {
      chart: {
        // height: 500,
        // width: 'auto'
        // type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        // plotLines: [],
      },
      navigator: {
        enabled: false
      },
      series: [
        {
          name: "pH",
          data: [],
          tooltip: {
            valueDecimals: 2
          },
          color: "#015781",
        }
      ],
      legend: {
          enabled: false
      },
    },

    // Highchart DO
    highchartDO: {
      chart: {
        // height: 500,
        // width: 'auto'
        // type: 'spline'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      exporting: {
        enabled: false,
        exportButton: {
            enabled: false
        },
      },
      credits: {
        enabled: false
      },
      // tooltip: {
      //     shared: false,
      // },
      xAxis: {
          // type: 'category'
      },
      yAxis: {
        // plotLines: [],
      },
      navigator: {
        enabled: false
      },
      series: [
        {
          name: "DO",
          data: [],
          tooltip: {
            valueDecimals: 2
          },
          color: "#015781",
        }
      ],
      legend: {
          enabled: false
      },
    },
    
  }),

  mounted() {
    this.getDataAllDropdown();
    this.getDataMinMax();
    // this.loadCurrentDatetimeReading();
    // console.log(this.$store.getters.user.token);
  },

  methods: {
    
    getDataAllDropdown(){

      this.currentView = "loading";

      axios.get(this.globalUrl + 'data/alldropdowns',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
              // 'Accept': 'application/json',
              // 'Content-Type': 'application/json',
          },
      })
      .then((response) => {
            let dataStation = response.data["mpdc_station"];
            // console.log(dataStation);

            this.listStation = dataStation;

            
            let myInterval = setInterval(() => {

                this.currentView = "table all";

                clearInterval(myInterval);
            }, 1000);
      })
      .catch((error) => {
          console.log(error.response);
          this.currentView = "table all";
      });

    },

    getDataMinMax(){

      axios.get(this.globalUrl + 'data/pulau?stationid=all',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;
            // console.log(data);

            this.dataMinMax = data;

            this.getDataDashboard();
            
      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    getDataDashboard(){

      axios.get(this.globalUrl + 'data/dashboard',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;
            
            let keys = Object.keys(data[0])
            for(let i in data){

              data[i]["DATETIME_UI"] = this.displayUiDate(data[i]["DATETIME"]);

              for(let x in keys){
                if(data[i][keys[x]] == null){
                  data[i][keys[x]] = "N/A"
                }
              }

            }

            
            for(let i in this.listStation){
              for(let x in data){
                if(data[x].STATION_ID == this.listStation[i].stationid){
                  data[x]["STATION_NAME"] = this.listStation[i].stationname
                }
              }
            }


            let keys2 = Object.keys(this.dataMinMax[0])
            for(let i in this.dataMinMax){
              for(let x in data){
                if(data[x].STATION_ID == this.dataMinMax[i].stationid){
                  for(let y in keys2){
                    if(keys2[y] != "stationid"){
                      data[x][keys2[y]] = this.dataMinMax[i][keys2[y]];
                    }
                  }
                }
              }
            }

            this.dataDashboard = data;
            this.dataBodyParams = data;

      })
      .catch((error) => {
          console.log(error.response);
          this.currentView = "table all";
      });

    },

    openDetailsStation(data){

      // console.log(data);

      this.dataImgSelected = data;

      this.loadDataParamsSingleStation(data.stationid)
      this.loadDataParamsSingleStation2(data.stationid)
      this.loadHighchartTemperature(data.stationid);
      this.loadHighchartSalinity(data.stationid);
      this.loadHighchartTurbidity(data.stationid);
      this.loadHighchartpH(data.stationid);
      this.loadHighchartDO(data.stationid);

      bus.$emit('triggerMap', {focus: 'zoomLocation', info: data});

      this.currentView = 'table single';
      
    },
    
    // clickTab(tab){
    //   if(tab == "Temperature"){
    //     // this.loadHighchartTemperature(this.dataImgSelected.stationid);
    //     this.refHighchartTemperature = this.$refs.refHighchartTemperature.chart;
    //     this.refHighchartTemperature.redraw();

    //     console.log(this.refHighchartTemperature);
    //   }
    //   else if(tab == "Salinity"){
    //     this.loadHighchartSalinity(this.dataImgSelected.stationid);
    //     // this.$refs.refHighchartSalinity.chart.redraw();
    //   }
    //   else if(tab == "Turbidity"){
    //     this.loadHighchartTurbidity(this.dataImgSelected.stationid);
    //     // this.$refs.refHighchartTurbidity.chart.redraw();
    //   }
    //   else if(tab == "pH"){
    //     this.loadHighchartpH(this.dataImgSelected.stationid);
    //     // this.$refs.refHighchartpH.chart.redraw();
    //   }
    //   else if(tab == "DO"){
    //     this.loadHighchartDO(this.dataImgSelected.stationid);
    //     // this.$refs.refHighchartDO.chart.redraw();
    //   }
    // },

    loadDataParamsSingleStation(station){

      axios.get(this.globalUrl+'data/pulau?stationid='+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let data = response.data;

            let keysData = Object.keys(data);
            for(let i in this.listParams){
              for(let x in keysData){
                
                if(keysData[x].includes("min") || keysData[x].includes("max")){

                  if(this.listParams[i].keyWords == keysData[x].split("min")[0]){
                    this.listParams[i].min = data[keysData[x]]
                  }
                  if(this.listParams[i].keyWords == keysData[x].split("max")[0]){
                    this.listParams[i].max = data[keysData[x]]
                  }

                }

              }
            }

            // console.log(this.listParams);

      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    loadDataParamsSingleStation2(station){

      let keys = Object.keys(this.dataDashboard[0]);
      for(let i in this.dataDashboard){
        if(this.dataDashboard[i].STATION_ID == station){

          this.currentDatetime = this.dataDashboard[i].DATETIME

          for(let x in keys){
            for(let z in this.listParams){
              if(this.listParams[z].keyWordsTableDashboard == keys[x]){
                this.listParams[z].value = this.dataDashboard[i][keys[x]]? parseFloat(this.dataDashboard[i][keys[x]])  : null;
              }
            }
          }

        }
      }
      
    //   axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
    //   {
    //       headers: {
    //           Authorization: "Bearer " + this.$store.getters.user.token,
    //       },
    //   })
    //   .then((response) => {
    //         let dataSeries = response.data["series"];
    //         let dataParams = response.data["param"];


    //         for(let i in this.listParams){
    //           for(let x in dataParams){
    //             if(this.listParams[i].tab.toUpperCase() == dataParams[x].name){
    //               this.listParams[i].value = dataParams[x].data.filter(n => n).pop()
    //             }
    //           }
    //         }

    //         // console.log(new Date(dataSeries[0].data.pop()).toISOString().substr(0, 19));

    //   })
    //   .catch((error) => {
    //       console.log(error.response);
    //   });

    },

    // loadCurrentDatetimeReading(){

    //   var date = new Date();
    //   let arrayMins15 = [0,15,30,45]

    //   if((date.getMinutes() % 15) === 0){ 
    //     this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 17).replace("T"," ") + "00";
    //   }
    //   else{
    //     for(let i in arrayMins15){
    //       if(date.getMinutes() >= arrayMins15[i]){
    //         if(arrayMins15[i] == 0){
    //           this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 14).replace("T"," ") +"00:00";
    //         }
    //         else{
    //           this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 14).replace("T"," ") + arrayMins15[i] +":00";
    //         }
    //       }
    //     }
    //   }

    //   setInterval(function() { 
    //       if((date.getMinutes() % 15) === 0){ 
    //         this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19).replace("T"," ");
    //       }
    //       else{
    //         if((date.getMinutes() % 15) === 0){ 
    //           this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 17).replace("T"," ") + "00";
    //         }
    //         else{
    //           for(let i in arrayMins15){
    //             if(date.getMinutes() >= arrayMins15[i]){
    //               if(arrayMins15[i] == 0){
    //                 this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 14).replace("T"," ") +"00:00";
    //               }
    //               else{
    //                 this.currentDatetime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 14).replace("T"," ") + arrayMins15[i] +":00";
    //               }
    //             }
    //           }
    //         }
    //       }
    //   }, 30000);


    //   // this.currentDatetime = new Date(dataSeries[0].data.pop()).toISOString().substr(0, 19).replace("T"," ");
    // },

    loadHighchartTemperature(station){

      this.highchartTemperature.series[0].data = [];

      axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];


            // for(let i in dataSeries[0].data){
            //   dataSeries[0].data[i] = new Date(dataSeries[0].data[i]).toISOString().substr(0, 19)
            // }
            // console.log(response.data);

            if(dataSeries.length != 0 && dataParams.length != 0){

              let dataParamsSelected = [];
              for(let i in dataParams){
                if(dataParams[i].name == "TEMPERATURE"){
                  dataParamsSelected.push(dataParams[i]);
                }
              }

              // console.log(dataParamsSelected);

              for(let i in dataSeries[0].data){
                for(let x in dataParamsSelected[0].data){
                  if(i == x){
                    this.highchartTemperature.series[0].data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                  }
                }
              }

            }
            
            

      })
      .catch((error) => {
          console.log(error.response);

          if(error.response.status === 500){
            this.snackbarError = true;
            this.textError = "Internal Server Error (500)"
          }
      });

    },

    loadHighchartSalinity(station){

      this.highchartSalinity.series[0].data = [];

      axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];

            if(dataSeries.length != 0 && dataParams.length != 0){

              let dataParamsSelected = [];
              for(let i in dataParams){
                if(dataParams[i].name == "SALINITY"){
                  dataParamsSelected.push(dataParams[i]);
                }
              }

              for(let i in dataSeries[0].data){
                for(let x in dataParamsSelected[0].data){
                  if(i == x){
                    this.highchartSalinity.series[0].data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                  }
                }
              }

            }
            
            

      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    loadHighchartTurbidity(station){

      this.highchartTurbidity.series[0].data = [];

      axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];

            if(dataSeries.length != 0 && dataParams.length != 0){

              let dataParamsSelected = [];
              for(let i in dataParams){
                if(dataParams[i].name == "TURBIDITY"){
                  dataParamsSelected.push(dataParams[i]);
                }
              }

              for(let i in dataSeries[0].data){
                for(let x in dataParamsSelected[0].data){
                  if(i == x){
                    this.highchartTurbidity.series[0].data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                  }
                }
              }

            }
            
            

      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    loadHighchartpH(station){

      this.highchartpH.series[0].data = [];

      axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];

            if(dataSeries.length != 0 && dataParams.length != 0){

              let dataParamsSelected = [];
              for(let i in dataParams){
                if(dataParams[i].name == "PH"){
                  dataParamsSelected.push(dataParams[i]);
                }
              }

              for(let i in dataSeries[0].data){
                for(let x in dataParamsSelected[0].data){
                  if(i == x){
                    this.highchartpH.series[0].data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                  }
                }
              }

            }
            
            

      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    loadHighchartDO(station){

      this.highchartDO.series[0].data = [];

      axios.get(this.globalUrl + "data/chartpulau?stationid="+station,
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataSeries = response.data["series"];
            let dataParams = response.data["param"];

            if(dataSeries.length != 0 && dataParams.length != 0){

              let dataParamsSelected = [];
              for(let i in dataParams){
                if(dataParams[i].name == "DO"){
                  dataParamsSelected.push(dataParams[i]);
                }
              }

              for(let i in dataSeries[0].data){
                for(let x in dataParamsSelected[0].data){
                  if(i == x){
                    this.highchartDO.series[0].data.push([dataSeries[0].data[i],dataParamsSelected[0].data[x]])
                  }
                }
              }

            }
            
            

      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    backToMainDashboard(){
      this.currentView = 'table all';
      bus.$emit('triggerMap', {focus: 'backHomeMap', info: null});
      this.tabContentSinglePulau = 0;

      this.highchartTemperature
    },

  },

  created (){
        bus.$on('triggerTableDashboard', (data) => {

          if (data.focus == "displayDetailsSinglePulau") {
            this.openDetailsStation(data.info)
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';


// Card --------------------------------------
.card-img-pulau{
  border-bottom: 2px solid #a9a9a9 !important;
}

.card-img-pulau:hover{
  border-bottom: 5px solid #30aabc !important;
  color: #30aabc !important;
  cursor: pointer !important;
}
.card-img-pulau > span:hover{
  color: #30aabc !important;
}

// .card-img-details{
//   background: linear-gradient(159deg, #ffffff 76%, #015781 105%) 0 0/100% no-repeat;
// }

// Div template/background -------------------------------------
.content-right_div-dashboard{
  overflow-y: auto;
  height: calc(100vh - 70px);
  border-bottom: 1px solid grey;
}

.container-not-loading{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 76px);
}

.alert-text{
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% { color: red; font-weight: bold; }
  10% { color: rgb(0, 0, 0); font-weight: 300; }
  50% { color: red; font-weight: bold; }
  100% { color: red; font-weight: bold; }
}


// Highchart ------------------------------------------------
.content-highchart-dashboard{
  height: calc(100vh - 440px);
  width: calc(100% - 15px);
  overflow-y: auto;
  border-top: 0.1px solid #91e4fb;
  // border-left: 0.4px solid #ccc;
  // border-right: 0.4px solid #ccc;
  // border-bottom: 0.4px solid #ccc;
}

.highchartTemperature, .highchartSalinity, .highchartTurbidity, .highchartpH, .highchartDO{
  height: calc(100vh - 428px);
}


// Solve Problem ---------------------------------------------------
// (Setting 5 colums in one row)
@media (min-width: 1264px) and (max-width: 1903px) {
    .flex.lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
}

// Loading -------------------------------------------------------
.container-loading{
  // background: black;
  height: calc(100vh - 76px);
}

@-webkit-keyframes dominoes {
  0% {
    opacity: 0; }
  20% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  60% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg));
            transform: rotate(calc(var(--rotation) * 1deg)); }
  90%,
  100% {
    opacity: 0;
    -webkit-transform: rotate(calc(var(--rotation) * 1deg));
            transform: rotate(calc(var(--rotation) * 1deg)); } }
@keyframes dominoes {
  0% {
    opacity: 0; }
  20% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  60% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg));
            transform: rotate(calc(var(--rotation) * 1deg)); }
  90%,
  100% {
    opacity: 0;
    -webkit-transform: rotate(calc(var(--rotation) * 1deg));
            transform: rotate(calc(var(--rotation) * 1deg)); } }

.dominoes {
  display: inline-grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 10px);
  height: 40px; }
  .dominoes div {
    --rotation: 68;
    background-color: #015781;
    opacity: 0;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
    .dominoes div:nth-child(1) {
      -webkit-animation: dominoes 1s 0.2s infinite ease;
              animation: dominoes 1s 0.2s infinite ease; }
    .dominoes div:nth-child(2) {
      -webkit-animation: dominoes 1s 0.4s infinite ease;
              animation: dominoes 1s 0.4s infinite ease; }
    .dominoes div:nth-child(3) {
      -webkit-animation: dominoes 1s 0.6s infinite ease;
              animation: dominoes 1s 0.6s infinite ease; }
    .dominoes div:nth-child(4) {
      -webkit-animation: dominoes 1s 0.8s infinite ease;
              animation: dominoes 1s 0.8s infinite ease; }
    .dominoes div:nth-child(5) {
      -webkit-animation: dominoes 1s 1s infinite ease;
              animation: dominoes 1s 1s infinite ease;
      --rotation: 90; }


// div::-webkit-scrollbar {
//   width: 11px;
// }

// div::-webkit-scrollbar-track {
//   background: #154734;
// }

// div::-webkit-scrollbar-thumb {
//   background-color: #f3f0dd;
//   border-radius: 6px;
//   border: 3px solid #154734;
// }



</style>


