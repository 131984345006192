<template>
  <div>
    
    <v-row>

      <v-col cols="4" class="pb-0">
        <content-map></content-map>
      </v-col>

      <v-col class="pa-0">
        <content-table></content-table>
      </v-col>

    </v-row>

  </div>
</template>

<script>
// -- import something here --

import contentMap from '@/components/Dashboard/Map'
import contentTable from '@/components/Dashboard/Table'

export default {

  components: {
      'content-map': contentMap,
      'content-table': contentTable,
  },

  data: () => ({
    
  }),

  mounted() {
    // console.log(this.$store.getters.token);
  },

  methods: {
    
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


