<template>
  <div>
    
    <div id="map" class="map div-map">

      <div style="display: none;">

        <!-- Popup -->
        <v-card
        id="popup"
        width="300px"
        elevation="2"
        outlined
        v-show="statusPopup"
        >
          <v-system-bar
          color="primary"
          dark
          >
            <small class="white--text caption text-bold">IDENTIFIKASI</small>

            <v-spacer></v-spacer>

            <v-icon class="white--text cursor-pointer" @click="statusPopup = false">
              mdi-close
            </v-icon>
          </v-system-bar>
          
          <v-card-text class="pa-0 cust-v-expansion" dense>
            <v-expansion-panels
            dense
            accordion
            v-model="modelExpansionIdentify"
            class="expansion-identify-map"
            >
              <v-expansion-panel
              v-for="(item, i) in listIdentifyPulau"
              :key="i"
              dense
              class="px-0"
              >
                <v-expansion-panel-header>
                  
                  <!-- <span class="text-bold">
                      <v-badge
                      color="primary"
                      :content="item.data.length"
                      >
                        <span class="mr-3" v-html="item.shortname"></span>
                      </v-badge>
                  </span> -->

                  <span class="text-bold mr-3">
                    {{item.stationid}}
                  </span>
                </v-expansion-panel-header>
                

                <v-expansion-panel-content>

                  <!-- {{item.data[0].values_}} -->

                  <v-card dense class="px-0 mx-0">
                    <v-simple-table dense class="px-0">
                      <template>
                        <tbody>
                          <tr>
                            <td class="caption text-bold">ID STESEN:</td>
                            <td class="caption">{{item.data[0].values_.stationid}}</td>
                          </tr>
                          <tr>
                            <td class="caption text-bold">PULAU:</td>
                            <td class="caption">{{item.data[0].values_.stationname}}</td>
                          </tr>
                          <tr>
                            <td class="caption text-bold py-2">
                              <v-btn small color="primary" @click="moreDetailsPulau(item.data[0])">
                                <v-icon small class="mr-2">mdi-island</v-icon>
                                <small>More Details..</small>
                              </v-btn>
                            </td>
                            <td class="caption text-bold py-2"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                  
                  

                </v-expansion-panel-content>

              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          
        </v-card>
      </div>

      <!-- Top Left Tools -->
      <v-layout column class="top-left">

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                fab
                small
                color="white"
                class="primary--text"
                @click="map_home()"
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon>mdi-home</v-icon>
                </v-btn>
            </template>
            <span>Home</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                fab
                small
                color="white"
                class="primary--text mt-2"
                @click="map_zoom('in')"
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Zoom In</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                fab
                small
                color="white"
                class="primary--text mt-2"
                @click="map_zoom('out')"
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
            </template>
            <span>Zoom Out</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-show="currentViewSizeMap == 'small'"
                fab
                small
                color="white"
                class="primary--text mt-2"
                v-bind="attrs"
                v-on="on"
                @click="openBigMap()"
                >
                    <v-icon dense >mdi-arrow-top-right-bottom-left</v-icon>
                </v-btn>
                <v-btn
                v-show="currentViewSizeMap == 'big'"
                fab
                small
                color="white"
                class="primary--text mt-2"
                v-bind="attrs"
                v-on="on"
                @click="openSmallMap()"
                >
                    <v-icon dense >mdi-arrow-collapse</v-icon>
                </v-btn>
            </template>
            <span v-show="currentViewSizeMap == 'small'">Full Screen</span>
            <span v-show="currentViewSizeMap == 'big'">Close Full Screen</span>
        </v-tooltip>

      </v-layout>


      <!-- Bottom Left Tools -->
      <v-layout id="bottom-left" column class="bottom-left-small-map">
          <v-sheet
          rounded
          color="white"
          elevation="3"
          height="80"
          width="80"
          class="pa-1 cursor-pointer"
          @click="changeBasemap"
          >
            <v-img
            :src="baseImageLayer"
            max-height="80"
            max-width="80">
            </v-img>
          </v-sheet>
      </v-layout>

      <!-- Bottom Left Tools -->
      <v-layout id="bottom-left-2" column class="bottom-left-2-small-map">
          <v-card class="px-2" align="center" justify="center">
            <v-container fluid fill-height>
              <v-row>
                <v-col class="pa-1 justify-center" align="center" justify="center">
                  <v-checkbox
                  dense
                  hide-details
                  class="ma-0 pa-0"
                  v-model="modelCheckboxBaseLayerMap"
                  :label="mapDefault? 'Terrain' : 'Labels'"
                  @change="checkMoreTypeBasemap()"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
      </v-layout>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import { bus } from '@/main';


import "ol/ol.css"
import Map from "ol/Map";
import View from "ol/View";
import {OSM, ImageWMS, Vector as VectorSource} from 'ol/source';
import {
    Image as ImageLayer,
    Tile as TileLayer, 
    Vector as VectorLayer,
    Group as GroupLayer,
} from 'ol/layer';
import {Icon, Style, Stroke, Circle, Fill} from 'ol/style';
import {GeoJSON} from 'ol/format';
import { 
  defaults as defaultControls,
  ScaleLine,
  FullScreen
} from "ol/control";
import Overlay from 'ol/Overlay';
import * as olExtent from 'ol/extent';

export default {

  data: () => ({

    // Data From API
    dataStations: [],

    // Map Viewer
    map: null,
    view: null,
    extentSelangor: [
        100.75968958251872,
        2.398394058258205,
        102.0267618469735,
        4.020593021832656
    ],
    extentMalaysia: [
        99.12427436578567,
        0.2047327978579272,
        105.07886420953567,
        7.730367563482927
    ],


    // Vector Layer
    objVector: {},

    // Control/Tools Map
    mapFullScreen: null,
    scaleLineControl: null,
    mapDefault: true,
    baseImageLayer: require('@/assets/img/map/googleSateliteMap.jpg'),
    currentViewSizeMap: "small",
    modelCheckboxBaseLayerMap: false,
    
    // Identify Map
    overlay: null,
    identifyOverlay: null,
    contentIdentify: false,
    layerClass: "",
    layerIdentifyClicked: {},
    listIdentifyPulau: [],
    statusPopup: true,
    modelExpansionIdentify: false,
  }),

  mounted() {
    this.loadMap();
  },

  methods: {

    loadDataStation(){

      axios.get(this.globalUrl + 'data/alldropdowns',
      {
          headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
          },
      })
      .then((response) => {
            let dataStation = response.data["mpdc_station"];
            // console.log(dataStation);

            this.dataStations = dataStation;
            this.loadVectorLayer();


      })
      .catch((error) => {
          console.log(error.response);
      });

    },

    loadMap(){

      this.map = new Map({
        target: "map",
        layers: [
          this.baseMapLayer,
        ],
        view: new View({
          // zoom: 6,
          projection: 'EPSG:4326',
          center: [102.263060,3.310534],
          constrainResolution: true
        }),
        controls: defaultControls({
            zoom: false
        }).extend([
          // [this.mapFullScreen]
          new FullScreen
          // this.mapFullScreen
        ]),
      })
      this.view = this.map.getView();
      this.view.fit(this.extentMalaysia);

      
      this.loadDataStation();

      this.overlay = new Overlay({
          element: this.$el.querySelector("#popup"),
          autoPan: true,
          autoPanAnimation: {
              duration: 250
          },
          positioning: 'bottom-right',
          // offset: [10,0]
      });
      // overlay.setOffset([13, 5]);
      this.map.addOverlay(this.overlay);

      this.map.on('singleclick', this.getFeatureLayer);


      // this.map.on('moveend', (event) => {
      //     console.log(this.map.getView().calculateExtent(this.map.getSize()));
      // })

    },

    

    loadVectorLayer(){

      // Create empty vector source
      for(let i in this.dataStations){

        this.objVector["geoJson_"+this.dataStations[i].stationid] = {"type": "FeatureCollection","features": []};

        this.objVector["source_"+this.dataStations[i].stationid] = new VectorSource({wrapX: false});

        this.objVector["layer_"+this.dataStations[i].stationid] = new VectorLayer();

      }


      // Create GeoJSON
      var thisAllGeoJson = Object.keys(this.objVector).filter(x => x.includes('geoJson_'));
      for(let i in this.dataStations){
        for(let x in thisAllGeoJson){
          if (thisAllGeoJson[x] == "geoJson_"+this.dataStations[i].stationid) {
            this.objVector[thisAllGeoJson[x]].features.push(
                {
                    type: "Feature",
                    id: this.dataStations[i].stationid,
                    geometry: {
                        type: "Point",
                        coordinates: [
                            this.dataStations[i].longitude,
                            this.dataStations[i].latitude,
                        ]
                    },
                    geometry_name: "geom",
                    properties: {
                        stationid: this.dataStations[i].stationid,
                        stationname: this.dataStations[i].stationname,
                        jettyname: this.dataStations[i].jettyname,
                        stationstatus: this.dataStations[i].stationstatus,
                        stationaddress: this.dataStations[i].stationaddress,
                        phoneno: this.dataStations[i].phoneno,
                        faksno: this.dataStations[i].faksno,
                        email: this.dataStations[i].email,
                        lat:this.dataStations[i].latitude,
                        long:this.dataStations[i].longitude,
                        url: this.dataStations[i].url
                    }
                }
            );
          }
        }
      }


      // Load Vector Layer on map
      for(let i in this.dataStations){

        this.objVector["feature_"+this.dataStations[i].stationid] = new GeoJSON().readFeatures(this.objVector["geoJson_"+this.dataStations[i].stationid]);

        this.objVector["source_"+this.dataStations[i].stationid].addFeatures(this.objVector["feature_"+this.dataStations[i].stationid]);

        let imgVector = new Icon({
            anchor: [0.5, 35],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: require('@/assets/img/map/island_pin4.png'),
        });

        this.objVector["style_point_"+this.dataStations[i].stationid] = new Style({
            image: imgVector
        })

        this.objVector["layer_"+this.dataStations[i].stationid] = new VectorLayer({
            source: this.objVector["source_"+this.dataStations[i].stationid],
            style: this.objVector["style_point_"+this.dataStations[i].stationid],
            className: this.dataStations[i].stationid
        });

        // For Identify Part
        this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid] = {
            pulau: this.dataStations[i].stationid,
            dataLayer: []
        }

        this.map.addLayer(this.objVector["layer_"+this.dataStations[i].stationid]);
        // console.log(this.map.getLayers());

      }

      // console.log(this.objVector);



      

    },

    map_home(){
      this.view = this.map.getView()
      this.view.fit(this.extentMalaysia);
      // this.view.animate({
      //   zoom: this.view.getZoom(),
      //   duration: 2000
      // })
    },

    map_zoom(zoom){
      if (zoom == "in") {
        this.view.animate({
          zoom: this.view.getZoom()+1,
          duration: 200
        })
      }
      else if (zoom == "out") {
        this.view.animate({
          zoom: this.view.getZoom()-1,
          duration: 200
        })
      }
    },

    openBigMap(){
      let btnAsalFS = document.getElementsByClassName("ol-full-screen-false");
      let bsmapCg = document.getElementById("bottom-left");
      this.currentViewSizeMap = "big";
      btnAsalFS[0].click();
      bsmapCg.classList.remove("bottom-left-small-map");
      bsmapCg.classList.add("bottom-left-big-map");

      let myInterval = setInterval(() => {
          this.map_home();
          clearInterval(myInterval);
      }, 1000);
      
    },

    openSmallMap(){
      let btnAsalNFS = document.getElementsByClassName("ol-full-screen-true");
      let bsmapCg = document.getElementById("bottom-left");
      console.log(btnAsalNFS);
      this.currentViewSizeMap = "small";
      btnAsalNFS[0].click();
      bsmapCg.classList.remove("bottom-left-big-map");
      bsmapCg.classList.add("bottom-left-small-map");
      
      let myInterval = setInterval(() => {
          this.map_home();
          clearInterval(myInterval);
      }, 1000);
    },

    changeBasemap(){
      
      if(this.mapDefault == false){

        if(this.modelCheckboxBaseLayerMap == false){
          this.baseImageLayer = require('@/assets/img/map/googleSateliteMap.jpg');
          this.googleStreetMap.setVisible(true);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(false);
        }
        else{
          this.baseImageLayer = require('@/assets/img/map/googleSateliteMap.jpg');
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(true);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(false);
        }
        
      }
      else{

        if(this.modelCheckboxBaseLayerMap == false){
          this.baseImageLayer = require('@/assets/img/map/googleStreetMap.jpg');
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(true);
          this.googleSateliteMapLabel.setVisible(false);
        }
        else{
          this.baseImageLayer = require('@/assets/img/map/googleStreetMap.jpg');
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(true);
        }
        
      }

      this.mapDefault = !this.mapDefault;
    },

    checkMoreTypeBasemap(){

      if(this.mapDefault == false){

        if(this.modelCheckboxBaseLayerMap == false){
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(true);
          this.googleSateliteMapLabel.setVisible(false);
        }
        else{
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(true);
        }
        
      }
      else{

        if(this.modelCheckboxBaseLayerMap == false){
          this.googleStreetMap.setVisible(true);
          this.googleStreetMapTerrain.setVisible(false);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(false);
        }
        else{
          this.googleStreetMap.setVisible(false);
          this.googleStreetMapTerrain.setVisible(true);
          this.googleSateliteMap.setVisible(false);
          this.googleSateliteMapLabel.setVisible(false);
        }
        
      }

    },

    flyTo(location, done) {

        this.statusPopup = false;
        
        this.view.setZoom(17);
        const duration = 2000;
        const zoom = this.view.getZoom();
        let parts = 2;
        let called = false;

        function callback(complete) {
          --parts;
          if (called) {
            return;
          }
          if (parts === 0 || !complete) {
            called = true;
            done(complete);
          }
        }

        this.view.animate(
          {
            center: location,
            duration: duration,
          },
          callback
        );
        this.view.animate(
          {
            zoom: zoom - 9,
            duration: duration / 2,
          },
          {
            zoom: zoom,
            duration: duration / 2,
          },
          callback
        );

    },

    layerListOn() {
        let objLayerOn = [];
        let layerList = this.map.frameState_.layerStatesArray;
        for (let i in layerList) {
            if(layerList[i].layer.className_ != "ol-layer"){
              objLayerOn.push(layerList[i].layer);
            }
        }
        return objLayerOn;
    },

    getFeatureLayer: function(ev){

      this.listIdentifyPulau = [];
      let layerVisible = this.layerListOn();
      let valueRadius = 15;

      let x = ev.pixel[0];
      let y = ev.pixel[1];
      let extentSingleClickMap = olExtent.boundingExtent([ 
        this.map.getCoordinateFromPixel([x - valueRadius, y - valueRadius]),
        this.map.getCoordinateFromPixel([x + valueRadius, y - valueRadius]),
        this.map.getCoordinateFromPixel([x + valueRadius, y + valueRadius]),
        this.map.getCoordinateFromPixel([x - valueRadius, y + valueRadius])
      ]);

      // console.log(layerVisible);

      // console.log(this.dataStations);

      for(let i in this.dataStations){
        this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].dataLayer = [];
      }

      // console.log(this.layerIdentifyClicked);

      for(let i in layerVisible){
        let selected = layerVisible[i];
        // console.log(selected.getSource());
        if(selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection()).length > 0){
          // console.log(selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection()));
          console.log(selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection()));
          for(let x in this.dataStations){
            if (selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection())[0].values_.stationid === this.layerIdentifyClicked["layer_"+this.dataStations[x].stationid].pulau) {
              this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].pulau = selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection())[0].values_.stationid;
              this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].dataLayer = selected.getSource().getFeaturesInExtent(extentSingleClickMap,this.view.getProjection());
            }
          }
        }
      }


      for(let i in this.dataStations){
        if(this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].dataLayer.length > 0){

          this.listIdentifyPulau.push({
            stationid: this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].pulau,
            data: this.layerIdentifyClicked["layer_"+this.dataStations[i].stationid].dataLayer
          })
        }
      }

      console.log(this.listIdentifyPulau);

      if (this.listIdentifyPulau.length > 0) {
        this.statusPopup = true;
        this.modelExpansionIdentify = false;

        // positionkan extent map sesuai dgn coordinate popup
        this.overlay.setPosition(ev.coordinate);
      }
      else{
        this.statusPopup = false;
      }


    },

    moreDetailsPulau(data){
      data.values_["latitude"] = data.values_["lat"]
      data.values_["longitude"] = data.values_["long"]

      bus.$emit('triggerTableDashboard', {focus: 'displayDetailsSinglePulau', info: data.values_});
    }

    
  },

  created (){
        bus.$on('triggerMap', (data) => {

          // console.log(data);

          if (data.focus == "zoomLocation") {
            // console.log(data.info);
            this.flyTo([data.info.longitude,data.info.latitude],function () {});
          }
          else if (data.focus == "backHomeMap") {
            // console.log(data.info);
            this.map_home();
          }

        })
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.div-map{
  height: calc(100vh - 65px) !important;
}

.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.bottom-left-small-map {
  position: absolute;
  top: calc(100vh - 170px);
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.bottom-left-big-map {
  position: absolute;
  top: calc(100vh - 105px);
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.bottom-left-2-small-map {
  position: absolute;
  top: calc(100vh - 128px);
  left: 110px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.ol-full-screen.ol-unselectable.ol-control{
  display: none;
}

</style>


